<template>
  <div id="app">
    <div class="main">
      <ul class="cards">
        <img src="logo.png" width="250" height="100" alt="The Uncommons" />
        <h3>👇 Click 👇</h3>
        <br />
        <li class="cards_item" v-for="(card, index) in cards" :key="index">
          <div
            class="card"
            :style="`background-image: url(${card.image});`"
            @click="routeTo(card.link)"
          >
            <!-- <div class="card_image"><img src="https://picsum.photos/500/300/?image=5"></div> -->
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue';

export default {
  head: {
    name: "The Uncommons",
    title: {
      inner: "",
    },
    // Meta tags
    meta: [
      { name: "application-name", content: "The Uncommons" },
      { name: "description", content: "", id: "desc" }, // id to replace intead of create element
      // ...
      // Twitter
      { name: "twitter:title", content: "The Uncommons" },
      // with shorthand
      { n: "twitter:description", c: "" },
      // ...
      // Google+ / Schema.org
      { itemprop: "name", content: "The Uncommons" },
      { itemprop: "description", content: "" },
      // ...
      // Facebook / Open Graph
      { property: "fb:app_id", content: "123456789" },
      { property: "og:title", content: "The Uncommons" },
      // with shorthand
      { p: "og:image", c: "./logo.png" },
      // ...
    ],
  },
  data() {
    return {
      cards: [
        {
          image:
            "https://res.cloudinary.com/dop3ch3f/image/upload/q_auto:eco/v1617955130/Discord_Banner_1_xlsznt.png",
          link: "https://discord.com/invite/vewFazDcWK",
        },
        {
          image:
            "https://res.cloudinary.com/dop3ch3f/image/upload/q_auto:eco/v1616484425/2_bwg3eg.png",
          link: "https://www.webtoons.com/en/challenge/the-uncommons/list?title_no=384825",
        },
        {
          image:
            "https://res.cloudinary.com/dop3ch3f/image/upload/q_auto:eco/v1616484426/3_eqijse.png",
          link: "https://shop.weirdenough.com/products/the-uncommons-volume-1",
        },
        {
          image:
            "https://res.cloudinary.com/dop3ch3f/image/upload/q_auto:eco/v1616484425/1_eyern7.png",
          link: "https://shop.weirdenough.com/",
        },
      ],
    };
  },
  components: {
    // HelloWorld,
  },
  methods: {
    routeTo(url) {
      return window.open(url, "_blank");
    },
  },
};
</script>

<style>
/* Font */
@import url("https://fonts.googleapis.com/css?family=Quicksand:400,700");

/* Design */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  background-color: #ecf9ff;
}

body {
  color: #272727;
  font-family: "Quicksand", serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  padding: 1rem;
}

.main {
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

img {
  height: auto;
  max-width: 100%;
  text-align: center;
  display: block;
}

.btn {
  color: #ffffff;
  padding: 0.8rem;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: 400;
  display: block;
  width: 100%;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: transparent;
}

.btn:hover {
  background-color: rgba(255, 255, 255, 0.12);
}

.cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  overflow: scroll;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .card {
    /* background-color: transparent; */
    background-repeat: no-repeat;
    background-size: contain;
    height: 200px;
    border-radius: 0.25rem;
    /* box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25); */
    display: flex;
    overflow: hidden;
    width: 100%;
  }

  .cards_item {
    display: flex;
    align-items: center;
    padding-top: 1rem;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  /* for desktop */
  .cards_item {
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 80%;
  }

  .card {
    /* background-color: transparent; */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 0.25rem;
    /* box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25); */
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 500px;
  }
}

.card_content {
  padding: 1rem;
  background: linear-gradient(to bottom left, #ef8d9c 40%, #ffc39e 100%);
}

.card_title {
  color: #ffffff;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin: 0px;
}

.card_text {
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;
  font-weight: 400;
}
.made_by {
  font-weight: 400;
  font-size: 13px;
  margin-top: 35px;
  text-align: center;
}
</style>
